import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import Inventory from './Tables/Inventory';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FormModal from '../../components/FormModal';
import Button from "@mui/material/Button";
import DatePicker from "../../shared/DatePicker";
import IconButton from "@mui/material/IconButton";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Switch from '@mui/material/Switch';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { dateFormat, textfieldDateFormat, yesterday, dayCompare } from "../../utils/dateFunctions";
import HalfShellStartProcessStockForm from './Forms/HalfShellStartProcessStockForm';
import SelectWarehouse from '../../components/SelectWarehouse';
import HalfshellProcessForm from './Forms/HalfshellProcessForm';
import { CircularProgress } from '@mui/material';
import { getAPIDataParamsReturn, getAPIDataReturn, postApi } from '../../utils/apiFunction';
import set from 'date-fns-jalali/esm/set';
import { GetAllCacheData, addToCache } from "../../utils/cacheFunctions";
import ChooseStaffForm from './Forms/ChooseStaffForm';
import FinishStaffFromBench from './Forms/FinishStaffFromBenchForm';
import AddIcon from '@mui/icons-material/Add';
import AddNewBenchForm from './Forms/AddNewBenchForm';
import endOfDay from 'date-fns-jalali/endOfDay/index';
import startOfDay from 'date-fns-jalali/startOfDay/index';

var benchesSave = [{ id: 1, number: 1, staffMemberId: 1, total: 60, prn: '54324', processRunId: '2' },
{ id: 2, number: 2, staffMemberId: 2, total: 40, prn: '54324', processRunId: '2' },
{ id: 3, number: 3, staffMemberId: 3, total: 20, prn: '54123', processRunId: '2' },
{ id: 4, number: 4, staffMemberId: 4, total: 50, prn: '65432', processRunId: '2' },
{ id: 5, number: 5, staffMemberId: 5, total: 10, prn: '12324', processRunId: '2' },
{ id: 6, number: 6, staffMemberId: 6, total: 100, prn: '55454', processRunId: '2' },
{ id: 7, number: 7, staffMemberId: 7, total: 10, prn: '11224', processRunId: '2' },
{ id: 8, number: 8, staffMemberId: 8, total: 0, prn: '54111', processRunId: '2' },
{ id: 9, number: 9, staffMemberId: 9, total: 220, prn: '52224', processRunId: '2' },
{ id: 10, number: 10, staffMemberId: 10, total: 90, prn: '53333', processRunId: '2' },
{ id: 12, number: 12, staffMemberId: 12, total: 60, prn: '54121', processRunId: '2' },
]


const InventoryDash = (props) => {
    const Swal = useSwalWrapper();
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [newForm, setNew] = React.useState(false);
    const [rowData, setRowData] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [processed, setProcessed] = React.useState(false);
    const [selectedLine, setSelectedLine] = React.useState(false);
    const [warehouse, setWarehouse] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [staffMembers, setStaffMembers] = React.useState(null);
    const [benches, setBenches] = React.useState(null);
    const [selectedBench, setSelectedBench] = React.useState(null);
    const [inUse, setInUse] = React.useState(false);
    const [showGraphic, setShowGraphic] = React.useState(false);
    const [displayMode, setDisplayMode] = React.useState(false);
    const [openSetBench, setOpenSetBench] = React.useState(false);
    const [openEndBench, setOpenEndBench] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [editRoom, setEditRoom] = React.useState(false);
    const [openNewBench, setOpenNewBench] = React.useState(false);
    const [loadingBenchId, setLoadingBenchId] = React.useState(null);
    const refreshInterval = 15000;
    let timer;
    const savedCallback = React.useRef();
    const handleClose = () => {
        setReload(!reload);
        setOpen(false);
    }
    const handleCloseSetBench = () => {
        setReload(!reload);
        setOpenSetBench(false);
    }
    const handleCloseEndBench = () => {
        setReload(!reload);
        setOpenEndBench(false);
    }
    React.useEffect(() => {
        GetAllCacheData(setDisplayMode, 'displayModeHSR')
    }, []);
    React.useEffect(() => {
        if (displayMode) {
            reloadFunc();
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);

    }, [displayMode]);
    React.useEffect(() => {
        if (!displayMode) {
            if (!inUse) {
                reloadFunc();
            } else {
                clearInterval(timer);
            }
        }

        return () => clearInterval(timer);
    }, [inUse]);
    React.useEffect(() => {
        savedCallback.current = getData;
    }, [benches, warehouse, startDate]);

    const reloadFunc = () => {
        timer = !timer && setInterval(() => {
            console.log('TIMER')
            savedCallback.current(true);
            //getData(true);
        }, refreshInterval)
    }

    const getData = (update) => {
        //get benches may return staffmember
        //getAPIDataReturn('/StaffMember/Get/Current', setStaffMembers).then((data) => {
        //var benchesFormat = benchesSave.map((bench) => {
        //    return { ...bench, staffMember: data.find((staff) => staff.id === bench.staffMemberId) }
        //});
        //if (update) {
        //    if (newBenches) {
        //        setBenches(newBenches);
        //    }
        //    var testUpdate = [...benchesFormat];
        //    testUpdate[0].total = 30;
        //    testUpdate[5].total = 120;
        //    testUpdate[7].total = 40;
        //    //setNewBenches(benchesFormat);
        //    setNewBenches(testUpdate);
        //} else {
        //    setBenches(benchesFormat);
        //}
        //});
        if (warehouse) {
            console.log(warehouse);

            getAPIDataParamsReturn('/ProcessLine/Get', { WarehouseId: warehouse.id }).then((lines) => {
                getAPIDataParamsReturn('/Processing/GetTodaysLiveOutputs', { WarehouseId: warehouse.id, Date: textfieldDateFormat(startDate) }).then((PRoutputs) => {
                    getAPIDataReturn('/Processing/GetActiveProcessRuns').then((data) => {
                        var outputs = PRoutputs.reduce((acc, item) => {
                            if (!acc[item.staffMemberId]) {
                                acc[item.staffMemberId] = [];
                            }
                            acc[item.staffMemberId].push(item);
                            return acc;
                        }, {})
                        if (lines) {
                            var bchs = lines.map((line) => {
                                var listAdj = [];
                                var activeRunProc = data.find((run) => run.processLineId === line.id);
                                var benchBuild = { ...activeRunProc, benchDetails: line }
                                if (!activeRunProc) {
                                    var lastRun = PRoutputs.filter((run) => run.processLineId === line.id).sort((a, b) => { return a.start>b.start?1:-1 });
                                    benchBuild = {
                                        ...benchBuild, ...lastRun[0], notActive: true
                                    }
                                }
                                if (outputs[benchBuild.staffMemberId]) {
                                    benchBuild.totalShucked = outputs[benchBuild.staffMemberId].sort((a, b) => (new Date(a.start) > new Date(b.start ? 1 : -1))).reduce((acc, item) => {
                                        return acc + item.processRunOutputs.reduce((p, c) => {
                                            listAdj = [...listAdj, ...c.processRunDeltas]
                                            return p + c.quantity
                                        }, 0);
                                    }, 0);
                                    if (benches) {
                                        benchBuild.updateTotal = benchBuild.totalShucked - benches.find((bn) => bn.staffMemberId === benchBuild.staffMemberId)?.totalShucked
                                    }
                                } else {
                                    benchBuild.totalShucked = 0;
                                }
                                benchBuild.fullAdjustments = listAdj;
                                return benchBuild;
                            });
                            if (update) {
                                setBenches(bchs);
                            } else {
                                setBenches(bchs);
                            }
                        }
                        setLoadingBenchId(null);
                    })
                })
            })

        }

    }
    React.useEffect(() => {
        getData(false);
    }, [reload, warehouse]);

    const completeAll = () => {
        Swal.fire({
            html: '',
            icon: 'warning',
            title: 'Are you sure you want to complete all benches?',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue',
            cancelButtonText: 'No, I\'ll edit it',

        }).then(result => {
            if (result.value) {
                //submit();
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    const openHalfshellProcessForm = (select) => (e) => {
        setOpen(true);
        setSelectedBench(select);
    }
    const assignToBench = (select) => (e) => {
        setOpenSetBench(true);
        setSelectedBench(select);
    }
    const removeUserFromBench = (bench) => (e) => {
        e.stopPropagation();
        setSelectedBench(bench);
        setOpenEndBench(true);
    }
    const openAddNewBench = (e) => {
        setOpenNewBench(true);
    }
    const handleCloseNewBench = () => {
        setReload(!reload);
        setOpenNewBench(false);
    }
    const loadingBench = (benchId) => () => {
        setLoadingBenchId(benchId);
    }
    console.log(benches);
    return (
        <div onMouseOver={() => { return !inUse ? setInUse(true) : null; }} onMouseLeave={() => { return inUse ? setInUse(false) : null; }}>

            <FormModal open={open} handleClose={handleClose} title={"Count Shucked (" + selectedBench?.staffMember?.firstname + ' ' + selectedBench?.staffMember?.surname + ")"} fixedWidth>
                <HalfshellProcessForm startDate={startDate} access={props.access} handleClose={handleClose} selectedBench={selectedBench} warehouseId={warehouse?.id} warehouse={warehouse} />
            </FormModal >
            <FormModal open={openSetBench} handleClose={handleCloseSetBench} title={"Assign PRN to a staff member"} noWidth>
                <HalfShellStartProcessStockForm loadingBench={loadingBench(selectedBench?.benchDetails?.id)} access={props.access} handleClose={handleCloseSetBench} selectedBench={selectedBench} warehouseId={warehouse?.id} warehouse={warehouse} reload={() => { setReload(!reload) }} />
            </FormModal >
            <FormModal open={openEndBench} handleClose={handleCloseEndBench} title={"Complete staff member for day and remove from bench"} noWidth>
                <FinishStaffFromBench access={props.access} handleClose={handleCloseEndBench} selectedBench={selectedBench} warehouseId={warehouse?.id} reload={() => { setReload(!reload) }} />
            </FormModal >
            <FormModal open={openNewBench} handleClose={handleCloseNewBench} title={"Add New Bench"} noWidth>
                <AddNewBenchForm access={props.access} handleClose={handleCloseEndBench} selectedBench={selectedBench} warehouseId={warehouse?.id} reload={() => { setReload(!reload) }} />
            </FormModal >
            <Grid container spacing={3.5}>
                <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'left' }}>
                    <Grid key={props.key} container spacing={2} mb={1} >
                        <div style={{ paddingRight: '20px', paddingBottom: '10px' }} >
                            <SelectWarehouse useCache access={props.access} enforceAccess={true} warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} all />
                        </div>
                        <div style={{ marginTop: '-8px' }} >
                            <DatePicker date={startDate} setDate={setStartDate} label="Date" />
                        </div>
                        <div  >
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch checked={editRoom} onChange={(event) => { setEditRoom(event.target.checked) }} name="editroom" />}
                                    label="Edit Room"
                                    labelPlacement="top"
                                />
                            </FormGroup>
                        </div>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'right' }}>
                    {/*<Button style={{ width: '200px', height: '50px', marginRight: '20px' }} variant='outlined' aria-label="close" onClick={completeAll}> Complete all benchs</Button>*/}
                    <Typography style={{ display: 'inline' }}>Display Mode</Typography>
                    <Switch checked={displayMode} onChange={(event) => { addToCache(event.target.checked, 'displayModeHSR'); setDisplayMode(event.target.checked) }} name="header-fixed" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3.5}>
                        {(benches)?.filter((a)=>a.benchDetails?.isHalfshell).map((bench) => {
                            if (displayMode && !bench.staffMember) {
                                return null;
                            } else {
                                return <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                    {bench.staffMember ? <User loadingBenchId={loadingBenchId} displayMode={displayMode} refreshInterval={refreshInterval} updatedBench={bench} startNew={assignToBench(bench)} onClick={openHalfshellProcessForm(bench)} name={bench.staffMember?.firstname + ' ' + bench.staffMember?.surname} table={bench.benchDetails.description} total={bench.totalShucked} currPRN={bench.coreInventory?.lotSerialNbr} removeUserFromBench={removeUserFromBench(bench)} />
                                        : <BlankBench loadingBenchId={loadingBenchId} editRoom={editRoom} onClick={assignToBench(bench)} bench={bench} table={bench.benchDetails.description} reload={() => { setReload(!reload) }} />}
                                </Grid>
                            }
                        })}
                        {editRoom ? <Button style={{
                            width: '120px',
                            height: '150px',
                            marginLeft: '20px',
                            marginTop: '60px'
                        }} variant='outlined' aria-label="close" onClick={openAddNewBench}>
                            <AddIcon style={{ width: '50px', height: '50px' }} />
                        </Button> : null}
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
};

const User = (props) => {
    const [count, setCount] = React.useState(props.total)
    const [countOn, setCountOn] = React.useState(false)
    var animate = false;
    let timer;
    var totalDiff = null;
    var isActive = false;
    if (endOfDay(new Date(props.updatedBench.start)) > startOfDay(new Date())) {
        isActive = true;
    }
    if (props.updatedBench) {
        totalDiff = props.updatedBench.updateTotal
        if (totalDiff && totalDiff !== 0) {
            animate = true;
        }
    }
    React.useEffect(() => {
        if (props.updatedBench) {
            const totalDiffc = props.updatedBench.updateTotal
            if (totalDiffc && totalDiffc !== 0) {
                let start = props.total - totalDiffc;
                // first three numbers from props
                const end = props.total
                // if zero, return
                if (start === end) {
                    return () => clearInterval(timer);
                }
                var diff = (end - start) ? (end - start) : 0;
                // find duration per increment
                let incrementTime = (props.refreshInterval / 4) / Math.abs(diff);
                // timer increments start counter 
                // then updates count
                // ends if start reaches end
                setCountOn(true);
                timer = setInterval(() => {
                    if (diff > 0) {
                        start += 1;
                    } else {
                        start -= 1;
                    }
                    if (start === (end - 0.5)) {
                        start = start + 0.5;
                    }
                    setCount(start)
                    if (start >= end) {
                        setCountOn(false);
                        clearInterval(timer)
                    }
                }, incrementTime);
            }
        }
        return () => clearInterval(timer);
        // dependency array
    }, [props.updatedBench, props.total]);

    var cardStyle = {
        position: 'relative',
    }
    const exitIcon = {
        position: 'absolute',
        right: '1%',
        top: '1%',
    }
    if (countOn) {
        cardStyle.backgroundColor = ((totalDiff > 0) ? 'limegreen' : (totalDiff < 0 ? 'orangered' : '#FFFFFF'))
    }
    if (props.updatedBench.notActive) {
        cardStyle.backgroundColor = 'lightgoldenrodyellow'
    }
    return (<Card sx={{ mb: 4 }} style={{ textAlign: 'center' }} style={cardStyle} onClick={isActive ? (props.updatedBench.notActive?props.startNew:props.onClick) : (() => { })} >
        {/*{(totalDiff && totalDiff !== 0) ? <Typography*/}
        {/*    variant={"h4"}*/}
        {/*    color={"common.black"}*/}
        {/*    mb={2}*/}
        {/*    style={{ position: 'absolute', right: '10%', top: '50%' }}*/}
        {/*>{totalDiff > 0 ? '+' : '-'}{Math.abs(totalDiff)}</Typography> : null}*/}
        {isActive ? null : <div style={{
            position: 'absolute',
            left: '-13%',
            top: '60%',
            color: 'red',
            transform: 'rotate(45deg)',
            fontSize: '110%',
            fontWeight: 'bold',
            background: 'white',
            width: '400px',
            borderTop: '1px solid red',
            borderBottom: '1px solid red'
        }}>Please Close Yesterdays Job</div>}
        <CardContent>
            {props.updatedBench.notActive ? null:<IconButton style={exitIcon} aria-label="close" onClick={props.removeUserFromBench}>
                <CloseIcon />
            </IconButton>}
            <Div sx={{ flex: 1, }}>
                <Typography
                    variant={"p"}
                    color={"common.black"}
                    mb={2}
                >Bench: {props.table}</Typography>
                <Typography
                    variant={"h3"}
                    color={"common.black"}
                    mb={2}
                >{props.name}</Typography>
                <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                >{"Current PRN:"}</Typography>
                <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                >{props.currPRN ? props.currPRN : <span style={{color:'red'}} >Not Active</span>}</Typography>
                <Typography
                    variant={"h1"}
                    color={"text.secondary"}
                    sx={{ fontSize: '500%' }}
                >{animate ? count : props.total}</Typography>
            </Div>


            {props.displayMode ? <Div>
                {/*{ ?*/}
                <Typography
                    variant={"h4"}
                    color={"common.black"}
                    mb={2}
                    style={{ padding: '3px' }}
                >{props.updatedBench?.fullAdjustments.map((val) => { return <div style={{ display: 'inline', paddingRight: '10px', color: (val.delta > 0 ? 'limegreen' : 'orangered') }} >{val.delta > 0 ? '+' : ''}{val.delta} </div> })}</Typography>
            </Div> : null}
        </CardContent>
    </Card >
    );
}
const BlankBench = (props) => {
    const Swal = useSwalWrapper();
    var isLoading = false;
    if (props.loadingBenchId === props.bench.benchDetails.id) {
        isLoading = true;
    }
    var cardStyle = {
        position: 'relative',
        minHeight: '220px',
    }
    const removeBench = (e) => {
        e.stopPropagation();
        Swal.fire({
            html: 'Are you sure you want to remove this bench',
            icon: 'warning',
            title: '',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue',
            cancelButtonText: 'No, go back',

        }).then(result => {
            if (result.value) {
                submit();
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }
    const submit = async () => {
        const endparams = {
            Description: props.bench.benchDetails.description,
            WarehouseId: props.bench.benchDetails.warehouseId,
            AllowMultiple: props.bench.benchDetails.allowMultiple,
            isCurrent: false
        }
        await postApi('/ProcessLine/Update/' + props.bench.benchDetails.id, endparams);
        props.reload();
    }
    return (<Card sx={{ mb: 4 }} style={cardStyle} onClick={isLoading ? (() => { }) :props.onClick} >

        <CardContent>

            <Div sx={{ flex: 1, }}>
                {props.editRoom ? <Button variant='outlined' style={{ width: '80%', height: '80%', position: 'absolute' }} aria-label="close" onClick={removeBench}>
                    <DeleteIcon style={{ width: '80%', height: '80%' }} />
                </Button> : null}
                <Typography
                    variant={"p"}
                    color={"common.black"}
                    mb={2}
                >Bench: {props.table}</Typography>
                {isLoading ? <CircularProgress style={{ width: '50%', height: '50%', position: 'absolute' }} /> : null}

            </Div>
        </CardContent>
    </Card >
    );
}




export default InventoryDash;
